class Concept {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    // スライダー
    const swiperSlides = document.getElementsByClassName("js-important-list-slider");
    const breakPoint = 850; // ブレークポイントを設定
    let swiper;
    let swiperBool;

    window.addEventListener(
      "load",
      () => {
        if (breakPoint >= window.innerWidth) {
          swiperBool = false;
        } else {
          createSwiper();
          swiperBool = true;
        }
      },
      false
    );

    window.addEventListener(
      "resize",
      () => {
        if (breakPoint >= window.innerWidth && swiperBool) {
          swiper.destroy(false, true);
          swiperBool = false;
        } else if (breakPoint < window.innerWidth && !swiperBool) {
          createSwiper();
          swiperBool = true;
        }
      },
      false
    );

    const createSwiper = () => {
      swiper = new Swiper('.js-important-list-slider', {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          formatFractionCurrent: function (number) {
            if (number < 10) {
                return '0' + number;
            } else {
                return number;
            }
          },
          formatFractionTotal: function (number) {
            if (number < 10) {
                return '0' + number;
            } else {
                return number;
            }
          },
        },
      });
    };

    // モーダル表示
    const modalOn = '[data-js=modal-on]';
    const modalContent = '[data-js=modal-content]';
    const modalInner = '[data-js=modal-inner]';
    const modalClose = '[data-js=modal-close]';
    $(modalOn).on('click',function(){
      let modalNum = $(this).data('modal');
      $(modalContent).fadeIn().find('[data-modal='+modalNum+']').fadeIn().css('display','flex');
    })
    $(modalClose).on('click',function(){
      $(modalContent).fadeOut();
      $(modalInner).fadeOut();
    })

  }
}
