class ArticleAccordion {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event() {
    const elements = document.querySelector('.js-acc');
    const btn = document.querySelector('.js-btn-more');
    const content = document.querySelector('.moreAccordion');
    const btnText = document.querySelector('.btnTxt')
    
    btn.addEventListener('click', () => {
      if(!content.classList.contains('is-active')){
        content.style.maxHeight = content.scrollHeight + 'px';
        content.classList.add('is-active');
        btn.classList.add('is-active');
        btnText.textContent = '閉じる'
        //コンテンツの実際の高さを代入
      } else {
        //コンテンツの高さを固定値を代入
        content.style.maxHeight = '86rem';
        content.classList.remove('is-active');
        btn.classList.remove('is-active');
        btnText.textContent = 'もっと見る'
      }
    });



    accordionBtn.addEventListener('click', () => {
      accordionContainer.classList.add("is-active")
      accordionBtn.classList.add("is-active")
    }, false);
  }
}
