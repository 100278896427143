class Accordion {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).on('click', (_el) => {
      $(_el.target).next().slideToggle();
      $(_el.target).find('.js-acco-active').toggleClass('is-active');
    })
  }
}
