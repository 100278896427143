class Concept {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    // スライダー
    const swiperSlides = document.getElementsByClassName("js-important-list-slider");
    const breakPoint = 850; // ブレークポイントを設定
    let swiper;
    let swiperBool;

    window.addEventListener(
      "load",
      () => {
        if (breakPoint >= window.innerWidth) {
          swiperBool = false;
        } else {
          createSwiper();
          swiperBool = true;
        }
      },
      false
    );

    window.addEventListener(
      "resize",
      () => {
        if (breakPoint >= window.innerWidth && swiperBool) {
          swiper.destroy(false, true);
          swiperBool = false;
        } else if (breakPoint < window.innerWidth && !swiperBool) {
          createSwiper();
          swiperBool = true;
        }
      },
      false
    );

    const createSwiper = () => {
      swiper = new Swiper('.js-important-list-slider', {
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          formatFractionCurrent: function (number) {
            if (number < 10) {
                return '0' + number;
            } else {
                return number;
            }
          },
          formatFractionTotal: function (number) {
            if (number < 10) {
                return '0' + number;
            } else {
                return number;
            }
          },
        },
      });
    };

    // モーダル表示
    const modalOn = '[data-js=modal-on]';
    const modalContent = '[data-js=modal-content]';
    const modalInner = '[data-js=modal-inner]';
    const modalClose = '[data-js=modal-close]';
    $(modalOn).on('click',function(){
      let modalNum = $(this).data('modal');
      $(modalContent).fadeIn().find('[data-modal='+modalNum+']').fadeIn().css('display','flex');
    })
    $(modalClose).on('click',function(){
      $(modalContent).fadeOut();
      $(modalInner).fadeOut();
    })

  }
}

// globals

$('a[href^="#"]').click(function(){
  // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
  var adjust = -100;
  // スクロールの速度（ミリ秒）
  var speed = 400;
  // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
  var href= $(this).attr("href");
  // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
  var target = $(href == "#" || href == "" ? 'html' : href);
  // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
  var position = target.offset().top + adjust;
  // スムーススクロール linear（等速） or swing（変速）
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

$(function(){
  const toggleSection = '.js-nav-toggle1';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        //toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(0)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(0)').addClass('is-active');
        }
      },
    });
  }
})
$(function(){
  const toggleSection = '.js-nav-toggle2';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        //toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(1)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(1)').addClass('is-active');
        }
      },
    });
  }
})
$(function(){
  const toggleSection = '.js-nav-toggle3';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        // toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(2)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(2)').addClass('is-active');
        }
      },
    });
  }
})
$(function(){
  const toggleSection = '.js-nav-toggle4';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        //toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(3)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(3)').addClass('is-active');
        }
      },
    });
  }
})

// ハンバーガーメニュー
jQuery(function(){
  const navToggle = '.js-hum-nav-toggle';
  const nav = '.js-hum-nav';
  $(navToggle).on('click',function(){
    $(navToggle).toggleClass('is-active');
    $(nav).toggleClass('is-active');
    $('body').toggleClass('is-hum-active');
    $(body).toggleClass('is-hum-active');
  })
})
class Salon {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    const salonSwiper = new Swiper('.js-salon-swiper',{
      loop: true,
      slidesPerView: 3.5,
      speed: 6000,
      simulateTouch: false,
      autoplay: {
        delay: 0
      }
    });
  }
}

class Service {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
  }
}

class Top {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    // --------------------------
    // MV GSAP
    // --------------------------
    let tl = gsap.timeline();
    tl.to('.g-loading p', 1, {
      filter: "blur(0)",
      opacity: 1,
      delay: .5,
    });
    tl.to('.g-loading p', 1, {
      scale: 2,
      opacity: 0,
      delay: .5,
      ease: CustomEase.create("custom", "M0,0 C0,0 0.493,0.201 0.516,0.214 0.534,0.224 0.678,0.34 0.702,0.372 0.732,0.412 0.778,0.456 0.832,0.544 0.877,0.618 0.916,0.68 0.935,0.737 1,0.93 1,1 1,1 "),
    });
    tl.to('.g-loading', 1, {
      filter: "blur(200px)",
      // opacity: '0',
      delay: .5,
    });
    tl.to('.g-loading', 1, {
      opacity: '0',
      onEnd: () => {
        $('.g-loading').css({'pointerEvents': 'none'});
      },
    }, '-=.8');
    tl.to('.g-mv-bg', 2, {
      width: '100%',
      delay: .3,
      onStart: () => {
        setTimeout(() => {
          $('.g-ttl *').addClass('is-active');
        }, 500);
      },
    });

    // --------------------------
    // ブログスライダー
    // --------------------------
    var swiper = "";

    let el = this.el;

    // 幅が768px以上のビューポートをターゲットとする条件を作成
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    function handleTabletChange(e) {
      if (e.matches) {
        if(swiper !== null && typeof swiper === 'object') {
          swiper.destroy(false, true);
        }
      } else {
        swiper = new Swiper("." + el, {
          slidesPerView: 3,
          spaceBetween: 40,
          speed: 700,
          pagination: {
            el: ".p-blog-swiper__pagination",
            type: "progressbar",
            // clickable: true,
          },
        });
      }
    }
    mediaQuery.addListener(handleTabletChange)
    handleTabletChange(mediaQuery)

    // --------------------------
    // 私たちにできること パララックス
    // --------------------------

    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.s-can > figure > img', {
      y: '200px',
      scrollTrigger: {
        trigger: '.s-can > figure',
        start: 'center center',
        end: 'center center-=250px',
        // markers: true,
        scrub: 5 // smoothing
      }
    })
  }
}

class Accordion {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    $("." + this.el).on('click', (_el) => {
      $(_el.target).next().slideToggle();
      $(_el.target).find('.js-acco-active').toggleClass('is-active');
    })
  }
}

class ArticleAccordion {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event() {
    const elements = document.querySelector('.js-acc');
    const btn = document.querySelector('.js-btn-more');
    const content = document.querySelector('.moreAccordion');
    const btnText = document.querySelector('.btnTxt')
    
    btn.addEventListener('click', () => {
      if(!content.classList.contains('is-active')){
        content.style.maxHeight = content.scrollHeight + 'px';
        content.classList.add('is-active');
        btn.classList.add('is-active');
        btnText.textContent = '閉じる'
        //コンテンツの実際の高さを代入
      } else {
        //コンテンツの高さを固定値を代入
        content.style.maxHeight = '86rem';
        content.classList.remove('is-active');
        btn.classList.remove('is-active');
        btnText.textContent = 'もっと見る'
      }
    });



    accordionBtn.addEventListener('click', () => {
      accordionContainer.classList.add("is-active")
      accordionBtn.classList.add("is-active")
    }, false);
  }
}

var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
// 参考サイト
// https://hacknote.jp/archives/15289/

var cookie = {
	/**
	 * 取得
	 * @param {string} cookieName 取得するCookieの名前
	 */
	get : function(cookieName) {
		if (!navigator.cookieEnabled) return false;

		var getCookieAllData  = document.cookie,
		    getCookieAllDatas = getCookieAllData.split(';'),
		    getCookieDatas    = {},
		    cookieKeyValue    = [],
		    cookieKey         = '',
		    cookieValue       = '';

		var hashLength = function(arr) {
			var result = 0, key = false;

			if (typeof arr !== 'object') return false;

			for (key in arr) result++;

			return result;
		};

		for (var i = 0, len = getCookieAllDatas.length; i < len; i++) {
			if (getCookieAllDatas[i] && getCookieAllDatas[i] !== '') {
				cookieKeyValue = getCookieAllDatas[i].split('=');
				cookieKey      = cookieKeyValue[0].replace(/^\s+/, '');
				cookieValue    = '';

				if (cookieKeyValue[1]) {
					cookieValue = decodeURIComponent(cookieKeyValue[1]);
				}

				getCookieDatas[cookieKey] = cookieValue;
			}
		}

		if (hashLength(getCookieDatas) > 0) {
			if (cookieName && cookieName in getCookieDatas) {
				return getCookieDatas[cookieName];
			} else {
				return getCookieDatas;
			}
		} else {
			return '';
		}
	},

	/**
	 * 設定
	 * @param {string} cookieName 設定するCookieの名前
	 * @param {string} value 設定する値
	 * @param {string} domain 有効ドメイン
	 * @param {string} path 有効パス
	 * @param {string} expires 有効期限
	 * @param {boolean} secure セキュアとするか
	 * @return {boolean} 設定に成功した場合はtrue、失敗した場合はfalseを返す
	 */
	set : function(cookieName, value, domain, path, expires, secure) {
		if (!navigator.cookieEnabled || !cookieName || !value) return false;

		var date, dt, ts, ts_after;

		var setCookieData = cookieName + '=' + encodeURIComponent(value) + ';';

		if (domain) setCookieData += 'domain=' + domain + ';';

		if (path) setCookieData += 'path=' + path + ';';

		if (expires) {
      dt = new Date();
      ts = dt.getTime();

      // 1時間後
      ts_after = ts + (1000 * 60 * 60 * expires);

      date = new Date(ts_after);
			setCookieData += 'expires=' + date.toGMTString() + ';';
		}

		if (secure && secure === true && location.protocol.match(/https/)) {
			setCookieData += 'secure';
		}

		document.cookie = setCookieData;

		return true;
	},

	/**
	 * 削除
	 * @param {string} cookieName 削除するCookieの名前
	 * @return {boolean} 削除に成功した場合はtrue、失敗した場合はfalseを返す
	 */
	del : function(cookieName) {
		if (!navigator.cookieEnabled || !cookieName) return false;

		var getCookieDatas = this.get();

		if (getCookieDatas[cookieName]) {
			var setCookieData = cookieName + '=;',
			    date          = new Date(0);

			setCookieData += 'expires=' + date.toGMTString() + '';

			document.cookie = setCookieData;

			return true;
		}

		return false;
	}
};
//noScroll
class ImgParallax {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    this.para_ele = document.querySelectorAll("." + this.el);
    this.para_ele.forEach((ele, index)=> {
      if(ele.classList.contains('js-parallax--reverse')) {
        this.translateY = '20px';
      } else {
        this.translateY = '-20px';
      }
      ele.classList.add(this.el + index);
      gsap.to('.' + this.el + index + ' img', {
        translateY: this.translateY,
        scrollTrigger: {
          trigger: '.' + this.el + index, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom',
          scrub: 3,
        }
      })
    })
  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemDelay = this.el + '-eachItem-delay';
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.2 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}



(() => {
  let imgParallax = new ImgParallax('js-parallax');
  let inviewEvent = new InviewEvent('.js-inview');
  let accordion = new Accordion('js-acco-trigger');
  let top = new Top('p-blog-swiper');
  let concept = new Concept('p-concept');
  let salon = new Salon('p-salon');
  let articleAccordion = new ArticleAccordion('js-moreAcc');
  let service = new Service('p-service');
  let articleAcordion = new ArticleAccordion('js-more');
})();

//DOMContentLoaded
$(() => {
});

//images resources loaded
$(window).on('load', () => {

  $(window).trigger('loading');
});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });