// globals

$('a[href^="#"]').click(function(){
  // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
  var adjust = -100;
  // スクロールの速度（ミリ秒）
  var speed = 400;
  // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
  var href= $(this).attr("href");
  // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
  var target = $(href == "#" || href == "" ? 'html' : href);
  // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
  var position = target.offset().top + adjust;
  // スムーススクロール linear（等速） or swing（変速）
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

$(function(){
  const toggleSection = '.js-nav-toggle1';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        //toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(0)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(0)').addClass('is-active');
        }
      },
    });
  }
})
$(function(){
  const toggleSection = '.js-nav-toggle2';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        //toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(1)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(1)').addClass('is-active');
        }
      },
    });
  }
})
$(function(){
  const toggleSection = '.js-nav-toggle3';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        // toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(2)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(2)').addClass('is-active');
        }
      },
    });
  }
})
$(function(){
  const toggleSection = '.js-nav-toggle4';
  const toggleNav = '.js-nav-target';
  if($(toggleNav).length){
    gsap.to(toggleNav, { // 動かしたい要素
      //opacity: "1",
      scrollTrigger: {
        trigger: toggleSection, // 要素がビューポートに入ったときにアニメーション開始
        start: 'top 120px', // アニメーション開始位置
        end: 'bottom top', // アニメーション終了位置
        //scrub: 0.1,
        // markers: true,
        //toggleClass: {targets: videoBg, className: 'is-active'},
        onEnter: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(3)').addClass('is-active');
        },
        onEnterBack: () => {
          $(toggleNav).find('li').removeClass('is-active');
          $(toggleNav).find('li:eq(3)').addClass('is-active');
        }
      },
    });
  }
})

// ハンバーガーメニュー
jQuery(function(){
  const navToggle = '.js-hum-nav-toggle';
  const nav = '.js-hum-nav';
  $(navToggle).on('click',function(){
    $(navToggle).toggleClass('is-active');
    $(nav).toggleClass('is-active');
    $('body').toggleClass('is-hum-active');
    $(body).toggleClass('is-hum-active');
  })
})