class Salon {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    const salonSwiper = new Swiper('.js-salon-swiper',{
      loop: true,
      slidesPerView: 3.5,
      speed: 6000,
      simulateTouch: false,
      autoplay: {
        delay: 0
      }
    });
  }
}
