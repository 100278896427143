class Top {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    // --------------------------
    // MV GSAP
    // --------------------------
    let tl = gsap.timeline();
    tl.to('.g-loading p', 1, {
      filter: "blur(0)",
      opacity: 1,
      delay: .5,
    });
    tl.to('.g-loading p', 1, {
      scale: 2,
      opacity: 0,
      delay: .5,
      ease: CustomEase.create("custom", "M0,0 C0,0 0.493,0.201 0.516,0.214 0.534,0.224 0.678,0.34 0.702,0.372 0.732,0.412 0.778,0.456 0.832,0.544 0.877,0.618 0.916,0.68 0.935,0.737 1,0.93 1,1 1,1 "),
    });
    tl.to('.g-loading', 1, {
      filter: "blur(200px)",
      // opacity: '0',
      delay: .5,
    });
    tl.to('.g-loading', 1, {
      opacity: '0',
      onEnd: () => {
        $('.g-loading').css({'pointerEvents': 'none'});
      },
    }, '-=.8');
    tl.to('.g-mv-bg', 2, {
      width: '100%',
      delay: .3,
      onStart: () => {
        setTimeout(() => {
          $('.g-ttl *').addClass('is-active');
        }, 500);
      },
    });

    // --------------------------
    // ブログスライダー
    // --------------------------
    var swiper = "";

    let el = this.el;

    // 幅が768px以上のビューポートをターゲットとする条件を作成
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    function handleTabletChange(e) {
      if (e.matches) {
        if(swiper !== null && typeof swiper === 'object') {
          swiper.destroy(false, true);
        }
      } else {
        swiper = new Swiper("." + el, {
          slidesPerView: 3,
          spaceBetween: 40,
          speed: 700,
          pagination: {
            el: ".p-blog-swiper__pagination",
            type: "progressbar",
            // clickable: true,
          },
        });
      }
    }
    mediaQuery.addListener(handleTabletChange)
    handleTabletChange(mediaQuery)

    // --------------------------
    // 私たちにできること パララックス
    // --------------------------

    gsap.registerPlugin(ScrollTrigger);

    gsap.to('.s-can > figure > img', {
      y: '200px',
      scrollTrigger: {
        trigger: '.s-can > figure',
        start: 'center center',
        end: 'center center-=250px',
        // markers: true,
        scrub: 5 // smoothing
      }
    })
  }
}
